import React, { useState, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import "react-datepicker/dist/react-datepicker.css";
import { FaStar } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { storage } from "../firebaseConfig";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import Spinner from "./Spinner";
import Config from "../utils/config";
import toast, { Toaster } from "react-hot-toast";

const FormUi = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateNaissance: "",
    lieuNaissance: "",
    sexe: "",
    adresse: "",
    ville: "",
    pays: "",
    codePostal: "",
    telephone: "",
    numeroSecurite: "",
    CNAPS: "",
    dateValiditeAgrementCNAPS: "",
    poste: "",
    englishLevel: "",
    permisVoiture: "",
    vehicule: "",
    email: "",
    nationality: "",
    numeroCIN: "",
    idCard: null,
    idCardRecto: null,
    carteVitale: null,
    carteProfessionnelle: null,
    diplomes: null,
    justificatif: null,
    rib: null,
    photo: null,
    cv: null,
    recommande: false,
    recommenderName: "",
    recommenderPhone: "",
  });
  const options = useMemo(() => countryList().getData(), []);

  const [fileError, setFileError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    const trimmedValue = String(value).trim();
    if (trimmedValue === "") {
      return `Le ${name} est requis.`;
    }
    return "";
  };

  const handleFileChange = (e, type) => {
    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ];

    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    const file = e.target.files && e.target.files[0];

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        setFileError(
          "Format de fichier invalide. Veuillez télécharger un fichier PDF ou DOCX."
        );
        return;
      }

      if (file.size > maxSize) {
        setFileError("La taille du fichier dépasse 5MB.");
        return;
      }

      setFileError("");
      setErrors({
        ...errors,
        [type]: "",
      });
      setFormData({
        ...formData,
        [type]: file,
      });
    }
  };

  const handleFileRemove = (type) => {
    setFormData({
      ...formData,
      [type]: null,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = [
      "firstName",
      "lastName",
      "dateNaissance",
      "lieuNaissance",
      "sexe",
      "adresse",
      "ville",
      "pays",
      "codePostal",
      "telephone",
      "numeroSecurite",
      "CNAPS",
      "dateValiditeAgrementCNAPS",
      "poste",
      "englishLevel",
      "permisVoiture",
      "vehicule",
      "email",
      "nationality",
      "numeroCIN",
      "idCard",
      "idCardRecto",
      "carteVitale",
      "carteProfessionnelle",
      "diplomes",
      "justificatif",
      "rib",
      "photo",
      "cv",
    ];

    const fieldErrors = {};
    requiredFields.forEach((fieldName) => {
      const fieldValue = formData[fieldName];
      if (!fieldValue || fieldValue === "" || fieldValue === null) {
        fieldErrors[fieldName] = `Le ${fieldName} est requis.`;
      }
    });

    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      return;
    }

    setUploading(true);

    axios
      .post(`${Config.baseURL}/check-email`, { email: formData.email })
      .then((response) => {
        const fileUploadPromises = [];
        const fileTypes = [
          "idCard",
          "idCardRecto",
          "carteVitale",
          "carteProfessionnelle",
          "diplomes",
          "justificatif",
          "rib",
          "photo",
          "cv",
        ];
        const fileUUIDs = {};

        fileTypes.forEach((type) => {
          const file = formData[type];
          if (file) {
            const uuid = v4();
            const fileRef = ref(storage, `files/${uuid}`);
            const uploadTask = uploadBytes(fileRef, file)
              .then((snapshot) => {
                fileUUIDs[type] = uuid;
                return getDownloadURL(snapshot.ref);
              })
              .catch((error) => {
                console.error("Error uploading file:", error);
                return null;
              });
            fileUploadPromises.push(uploadTask);
          }
        });

        Promise.all(fileUploadPromises)
          .then((urls) => {
            const updatedFormData = { ...formData };
            Object.keys(fileUUIDs).forEach((type) => {
              updatedFormData[type] = fileUUIDs[type];
            });

            axios
              .post(`${Config.baseURL}/apply`, updatedFormData)
              .then((response) => {
                setUploading(false);
                setSubmitted(true);
              })
              .catch((error) => {
                setUploading(false);
                toast.error(
                  "Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer plus tard."
                );
              });
          })
          .catch((error) => {
            setUploading(false);
            toast.error(
              "Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer plus tard.",
              {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 5000,
              }
            );
          });
      })
      .catch((error) => {
        // Email already exists, display alert
        setUploading(false);
        toast.error("Le condidat avec cette adresse e-mail existe déjà", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    const fieldError = validateField(name, value);
    setErrors({
      ...errors,
      [name]: fieldError,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(String(email).toLowerCase());
  };

  return (
    <div className="max-w-4xl mx-auto sm:px-4 py-8">
      {uploading && (
        <>
          <Spinner heading="Chargement de votre application en cours. Veuillez patienter un instant. Ne fermez pas cette fenêtre." />
        </>
      )}
      {!submitted && !uploading && (
        <form
          onSubmit={handleSubmit}
          className="space-y-6 bg-white  max-w-5xl sm:p-6 p-4 rounded-lg"
        >
          <div className="flex justify-center items-center flex-col">
            <img
              src="/logo.png"
              alt="Recruitment Form"
              className="sm:max-w-40 mt-6 max-w-20 h-auto"
            />
            <p className="p-4 sm:mt-20 mt-16 text-gray-600 max-w-3xl text-center">
              Formulaire de recrutement S.A.R.L E.I.S Les informations portées
              sur ce formulaire sont obligatoires. Elles font l’objet d’un
              traitement informatisé destiné au recrutement du personnel. Les
              destinataires des données sont : E.I.S Depuis la loi "informatique
              et libertés" du 6 janvier 1978 modifiée, vous bénéficiez d’un
              droit d’accès et de rectification aux informations qui vous
              concernent. Si vous souhaitez exercer ce droit et obtenir
              communication des informations vous concernant, veuillez vous
              adresser à <br></br>{" "}
              <a
                href="mailto:recrutement@eis-securite.fr"
                className="text-blue-500"
              >
                recrutement@eis-securite.fr
              </a>
            </p>
          </div>
          <div className="grid  grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="firstName"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Prénom <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre Prénom"
              />
              {errors.firstName && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Nom de famille <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre nom de famille"
              />
              {errors.lastName && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="dateNaissance"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Date de naissance <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                value={formData.dateNaissance}
                onChange={handleChange}
                id="dateNaissance"
                name="dateNaissance"
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="YYYY-MM-DD"
              />
              {errors.dateNaissance && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
            <div>
              <label
                htmlFor="lieuNaissance"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Lieu de naissance <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="lieuNaissance"
                name="lieuNaissance"
                value={formData.lieuNaissance}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre lieu de naissance"
              />
              {errors.lieuNaissance && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-lg mb-2 mt-8 font-medium text-gray-700">
              Sexe <span className="text-red-500">*</span>
            </label>
            <div className="mt-1">
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-blue-600"
                    name="sexe"
                    value="Homme"
                    checked={formData.sexe === "Homme"}
                    onChange={handleChange} // Change this line
                  />

                  <span className="ml-2">Homme</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-blue-600"
                    name="sexe"
                    value="Femme"
                    checked={formData.sexe === "Femme"}
                    onChange={handleChange} // Change this line
                  />
                  <span className="ml-2">Femme</span>
                </label>
                {errors.sexe && (
                  <p className="text-red-500">Ce champ est obligatoire</p>
                )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="email"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="****@hotmail.fr"
              />
              {errors.email && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="nationality"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Nationalité <span className="text-red-500">*</span>
              </label>

              <Select
                options={options}
                name="nationality"
                value={{
                  value: formData.nationality,
                  label: formData.nationality,
                }}
                onChange={(selectedOption) =>
                  handleChange({
                    target: {
                      name: "nationality",
                      value: selectedOption.label,
                    },
                  })
                }
              />
              {errors.nationality && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="numeroCIN"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Numéro de carte d'identité{" "}
                <span className="text-red-500">*</span>
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="numeroCIN"
                name="numeroCIN"
                value={formData.numeroCIN}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre numéro CIN"
              />
              {errors.numeroCIN && (
                <p className="text-red-500">
                  Le Numéro de carte d'identité est requis.
                </p>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="adresse"
              className="block text-lg mb-2 mt-8 font-medium text-gray-700"
            >
              Adresse <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="adresse"
              name="adresse"
              value={formData.adresse}
              onChange={handleChange}
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
              placeholder="Entrez votre adresse"
            />
            {errors.adresse && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="ville"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Ville <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="ville"
                name="ville"
                value={formData.ville}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre ville"
              />
              {errors.ville && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
            <div>
              <label
                htmlFor="pays"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Pays <span className="text-red-500">*</span>
              </label>

              <Select
                options={options}
                name="pays"
                value={{ value: formData.pays, label: formData.pays }}
                onChange={(selectedOption) =>
                  handleChange({
                    target: { name: "pays", value: selectedOption.label },
                  })
                }
              />
              {errors.pays && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="codePostal"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Code postal <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="codePostal"
                name="codePostal"
                value={formData.codePostal}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre code postal"
              />
              {errors.codePostal && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
            <div>
              <label
                htmlFor="telephone"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Téléphone <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="telephone"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre téléphone"
              />
              {errors.telephone && (
                <p className="text-red-500">Le Téléphone est requis.</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="numeroSecurite"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                N° de sécurité sociale <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="numeroSecurite"
                name="numeroSecurite"
                value={formData.numeroSecurite}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre numéro de sécurité"
              />
              {errors.numeroSecurite && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="CNAPS"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                N° d'agrément CNAPS <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="CNAPS"
                name="CNAPS"
                value={formData.CNAPS}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez votre CNAPS"
              />
              {errors.CNAPS && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="dateValiditeAgrementCNAPS"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Date de validité de votre agrément CNAPS{" "}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="dateValiditeAgrementCNAPS"
                name="dateValiditeAgrementCNAPS"
                value={formData.dateValiditeAgrementCNAPS}
                onChange={handleChange}
                className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                placeholder="Entrez la date de validité de votre agrément CNAPS"
              />
              {errors.dateValiditeAgrementCNAPS && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="poste"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Poste <span className="text-red-500">*</span>
              </label>
              <select
                id="poste"
                name="poste"
                value={formData.poste}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-blue-500"
              >
                <option value="">Veuillez sélectionner</option>
                <option value="ADS">ADS</option>
                <option value="SSIAP 1">SSIAP 1</option>
                <option value="SSIAP 2">SSIAP 2</option>
                <option value="MAITRE CHIEN">MAITRE CHIEN</option>
                <option value="OPERATEUR RAYONS X">OPERATEUR RAYONS X</option>
              </select>
              {errors.poste && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="englishLevel"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                English Level <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 flex">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <FaStar
                    key={rating}
                    className={`h-8 me-3 w-8 cursor-pointer ${
                      rating <= formData.englishLevel
                        ? "text-yellow-500"
                        : "text-gray-300"
                    }`}
                    onClick={() =>
                      handleChange({
                        target: {
                          name: "englishLevel",
                          value: rating.toString(),
                        },
                      })
                    }
                  />
                ))}
              </div>
              {errors.englishLevel && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="permisVoiture"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Permis de voiture <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    id="permisVoitureOui"
                    name="permisVoiture"
                    value="Oui"
                    checked={formData.permisVoiture === "Oui"}
                    onChange={handleChange}
                    className="form-radio h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2">Oui</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    id="permisVoitureNon"
                    name="permisVoiture"
                    value="Non"
                    checked={formData.permisVoiture === "Non"}
                    onChange={handleChange}
                    className="form-radio h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2">Non</span>
                </label>
              </div>
              {errors.permisVoiture && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="vehicule"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Etes-vous véhiculé <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    id="vehiculeOui"
                    name="vehicule"
                    value="Oui"
                    checked={formData.vehicule === "Oui"}
                    onChange={handleChange}
                    className="form-radio h-5 w-5 text-blue-600"
                  />

                  <span className="ml-2">Oui</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    id="vehiculeNon"
                    name="vehicule"
                    value="Non"
                    checked={formData.vehicule === "Non"}
                    onChange={handleChange}
                    className="form-radio h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2">Non</span>
                </label>
              </div>
              {errors.vehicule && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="recommande"
                className="block text-lg mb-2 mt-8 font-medium text-gray-700"
              >
                Est-ce que vous êtes recommandé par quelqu'un?
                <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    id="recommandeOui"
                    name="recommande"
                    value="Oui"
                    checked={formData.recommande === "Oui"}
                    onChange={handleChange}
                    className="form-radio h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2">Oui</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    id="recommandeNon"
                    name="recommande"
                    value="Non"
                    checked={formData.recommande === "Non"}
                    onChange={handleChange}
                    className="form-radio h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2">Non</span>
                </label>
              </div>
              {formData.recommande === "Oui" && (
                <div className="mt-4">
                  <label
                    htmlFor="recommenderName"
                    className="block text-lg mb-2 font-medium text-gray-700"
                  >
                    Nom de la personne qui vous recommande
                  </label>
                  <input
                    type="text"
                    id="recommenderName"
                    name="recommenderName"
                    value={formData.recommenderName || ""}
                    onChange={handleChange}
                    className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                  />

                  <label
                    htmlFor="recommenderPhone"
                    className="block text-lg mb-2 mt-4 font-medium text-gray-700"
                  >
                    Numéro de téléphone de la personne qui vous recommande
                  </label>
                  <input
                    type="tel"
                    id="recommenderPhone"
                    name="recommenderPhone"
                    value={formData.recommenderPhone || ""}
                    onChange={handleChange}
                    className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                  />
                </div>
              )}
              {errors.recommande && (
                <p className="text-red-500">Ce champ est obligatoire</p>
              )}
            </div>
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                Pièce d'identité ou carte de séjour (Verso)
                <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="idCardUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500  text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.idCard ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.idCard.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("idCard")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="idCardUpload"
                    id="idCardUpload"
                    className="hidden"
                    accept=".pdf, .docx, .png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, "idCard")}
                  />
                )}
              </label>
            </div>
            {errors.idCard && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                Pièce d'identité ou carte de séjour (Recto)
                <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="idCardUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500  text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.idCardRecto ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.idCardRecto.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("idCardRecto")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="idCardUpload"
                    id="idCardUpload"
                    className="hidden"
                    accept=".pdf, .docx, .png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, "idCardRecto")}
                  />
                )}
              </label>
            </div>
            {errors.idCardRecto && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                Carte vitale <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="carteVitaleUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500 text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.carteVitale ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.carteVitale.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("carteVitale")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="carteVitaleUpload"
                    id="carteVitaleUpload"
                    className="hidden"
                    accept=".pdf, .docx, .png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, "carteVitale")}
                  />
                )}
              </label>
            </div>
            {errors.carteVitale && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                Carte professionnelle <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="carteProfessionnelleUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500 text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.carteProfessionnelle ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.carteProfessionnelle.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("carteProfessionnelle")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="carteProfessionnelleUpload"
                    id="carteProfessionnelleUpload"
                    className="hidden"
                    accept=".pdf, .docx, .png, .jpg, .jpeg"
                    onChange={(e) =>
                      handleFileChange(e, "carteProfessionnelle")
                    }
                  />
                )}
              </label>
            </div>
            {errors.carteProfessionnelle && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                Diplômes <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="diplomesUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500 text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.diplomes ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.diplomes.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("diplomes")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="diplomesUpload"
                    id="diplomesUpload"
                    className="hidden"
                    accept=".pdf, .docx, .png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, "diplomes")}
                  />
                )}
              </label>
            </div>
            {errors.diplomes && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                Justificatif de domicile ou attestation d'hébergement{" "}
                <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="justificatifUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500 text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.justificatif ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.justificatif.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("justificatif")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="justificatifUpload"
                    id="justificatifUpload"
                    className="hidden"
                    accept=".pdf, .docx, .png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, "justificatif")}
                  />
                )}
              </label>
            </div>
            {errors.justificatif && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                RIB <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="ribUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500 text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.rib ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.rib.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("rib")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="ribUpload"
                    id="ribUpload"
                    className="hidden"
                    accept=".pdf, .docx, .png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, "rib")}
                  />
                )}
              </label>
            </div>
            {errors.rib && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                1 photo <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="photoUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500 text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.photo ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.photo.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("photo")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="photoUpload"
                    id="photoUpload"
                    className="hidden"
                    accept=".pdf, .docx, .png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, "photo")}
                  />
                )}
              </label>
            </div>
            {errors.photo && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="">
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                CV <span className="text-red-500">*</span>
              </label>
              <label
                htmlFor="cvUpload"
                className={`bg-slate-50 dark:bg-gray-100 text-slate-500 p-8 rounded text-lg mt-2 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 mx-auto`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 mb-4 fill-balck"
                  viewBox="0 0 32 32"
                >
                  <path
                    d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                    data-original="#000000"
                  ></path>
                </svg>
                <span className="text-slate-500 text-center">
                  {" "}
                  Glissez-déposez des fichiers ici{" "}
                </span>
                {formData.cv ? (
                  <>
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {formData.cv.name}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleFileRemove("cv")}
                      className="text-blue-500 mt-2 hover:underline focus:outline-none"
                    >
                      <AiOutlineDelete className="inline-block align-text-bottom mr-1" />{" "}
                      Retirer le fichier
                    </button>
                  </>
                ) : (
                  <input
                    type="file"
                    name="cvUpload"
                    id="cvUpload"
                    className="hidden"
                    accept=".pdf, .docx"
                    onChange={(e) => handleFileChange(e, "cv")}
                  />
                )}
              </label>
            </div>
            {errors.cv && (
              <p className="text-red-500">Ce champ est obligatoire</p>
            )}
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-purple-600 text-white sm:text-xl text-lg py-3 mt-14 sm:py-4 font-bold px-10 hover:bg-purple-700"
            >
              ENVOYER MA CANDIDATURE
            </button>
          </div>
        </form>
      )}
      {submitted && (
        <>
          <div className="flex justify-center items-center flex-col">
            <img
              src="/logo.png"
              alt="Recruitment Form"
              className="sm:max-w-40 mt-6 max-w-20 h-auto"
            />
          </div>
          <div className="text-center sm:text-3xl text-xl text-green-600 p-2 mt-16">
            Merci d’avoir pris le temps de repondre au questionnaire de
            candidature !Nous allons étudier votre dossier avec soin et revenons
            vers vous.{" "}
          </div>
        </>
      )}
      <Toaster />
    </div>
  );
};

export default FormUi;
