import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import Config from "../utils/config";
import { FaFilter } from "react-icons/fa";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function Dashboard() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    minAge: "18",
    maxAge: "70",
    sexe: "",
    ville: "",
    telephone: "",
    email: "",
    nationality: "",
    pays: "",
    status: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (filters = {}) => {
    try {
      const response = await axios.get(`${Config.baseURL}/get-all`, {
        params: filters,
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const headings = [
    { key: "createdAt", value: "Date de création" },
    { key: "firstName", value: "Prénom" },
    { key: "lastName", value: "Nom" },
    { key: "dateNaissance", value: "Age" },
    { key: "sexe", value: "Genre" },
    { key: "ville", value: "Ville" },
    { key: "nationality", value: "Nationalité" },
    { key: "status", value: "Status" },
  ];

  const calculateAge = (dateOfBirth) => {
    const currentDate = new Date();
    const targetDate = new Date(dateOfBirth);

    const currentYear = currentDate.getFullYear();
    const targetYear = targetDate.getFullYear();

    const yearDifference = currentYear - targetYear;

    if (
      currentDate.getMonth() < targetDate.getMonth() ||
      (currentDate.getMonth() === targetDate.getMonth() &&
        currentDate.getDate() < targetDate.getDate())
    ) {
      return yearDifference - 1;
    }

    return yearDifference;
  };

  const getRowDetail = (event, id) => {
    event.preventDefault();
    navigate(`/details/${id}`);
  };

  const handleFilterClick = () => {
    setIsFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value.trim(),
    });
  };

  const handleDelete = async (event, id) => {
    event.preventDefault();
    const confirmDelete = window.confirm(
      "Voulez-vous vraiment supprimer cet candidat ?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(`${Config.baseURL}/delete/${id}`);
        fetchData();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleFilter = () => {
    const currentDate = new Date();
    const filters = { ...inputValues };

    if (inputValues.minAge !== "" || inputValues.maxAge !== "") {
      const minBirthDate =
        inputValues.maxAge !== ""
          ? new Date(currentDate.getFullYear() - inputValues.maxAge, 0)
          : undefined;
      const maxBirthDate =
        inputValues.minAge !== ""
          ? new Date(
              currentDate.getFullYear() - inputValues.minAge - 1,
              11,
              31
            )
          : undefined;

      filters.dateNaissance = {};
      if (minBirthDate) {
        filters.dateNaissance.$gte = minBirthDate;
      }
      if (maxBirthDate) {
        filters.dateNaissance.$lte = maxBirthDate;
      }

      if (inputValues.status !== "") {
        filters.status = inputValues.status;
      }
    }

    fetchData(filters);
    setIsFilterModalOpen(false);
    setInputValues({
      firstName: "",
      lastName: "",
      minAge: "18",
      maxAge: "70",
      sexe: "",
      ville: "",
      telephone: "",
      email: "",
      nationality: "",
      pays: "",
      status: "",
    });
  };

  const handleMenuClick = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  if (loading) {
    return <Spinner heading="Chargement..." />;
  }

  return (
    <>
      <button
        onClick={handleFilterClick}
        className="mb-4 px-8 py-3 text-md font-semibold bg-indigo-500 text-white rounded-sm mt-16 m-4 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-200 flex items-center"
      >
        Filter <FaFilter className="ml-2" />
      </button>
      <div
        className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 ${
          isFilterModalOpen ? "block" : "hidden"
        }`}
      >
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-70"></div>

        <div className="modal-container m-20 bg-white max-w-xl mx-auto p-6 rounded-lg shadow-lg z-50">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Filtrer</h2>
            <button onClick={handleCloseFilterModal}>
              <svg
                className="w-6 h-6 text-gray-600 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="space-y-4">
            <div className="flex">
              <input
                type="text"
                name="firstName"
                value={inputValues.firstName}
                onChange={handleInputChange}
                placeholder="Prénom"
                className="w-full px-4 py-2 border me-4 border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
              />
              <input
                type="text"
                name="lastName"
                value={inputValues.lastName}
                onChange={handleInputChange}
                placeholder="Nom"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
              />
            </div>

            <div className="flex justify-between items-center">
              <input
                type="number"
                name="minAge"
                value={inputValues.minAge}
                onChange={handleInputChange}
                placeholder="Âge min"
                className="w-1/2 px-4 py-2 me-4 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
              />
              <input
                type="number"
                name="maxAge"
                value={inputValues.maxAge}
                onChange={handleInputChange}
                placeholder="Âge max"
                className="w-1/2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
              />
            </div>
            <div>
              <label className="block text-lg mt-8 font-medium text-gray-700">
                Sexe
              </label>
              <div className="">
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 text-blue-600"
                      name="sexe"
                      value="Homme"
                      checked={inputValues.sexe === "Homme"}
                      onChange={handleInputChange}
                    />
                    <span className="ml-2">Homme</span>
                  </label>
                  <label className="inline-flex ms-6 items-center mt-2">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 text-blue-600"
                      name="sexe"
                      value="Femme"
                      checked={inputValues.sexe === "Femme"}
                      onChange={handleInputChange}
                    />
                    <span className="ml-2">Femme</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex">
              <input
                type="text"
                name="telephone"
                value={inputValues.telephone}
                onChange={handleInputChange}
                placeholder="Téléphone"
                className="w-full px-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
              />
            </div>

            <input
              type="email"
              name="email"
              value={inputValues.email}
              onChange={handleInputChange}
              placeholder="E-mail"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
            />
          </div>
          <div className="flex mt-4">
            <select
              name="status"
              value={inputValues.status}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border me-4 border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
            >
              <option value="">Sélectionnez un statut</option>
              <option value="pending">En cours</option>
              <option value="rejected">Rejeté</option>
              <option value="approved">Approuvé</option>
            </select>
          </div>

          <button
            onClick={handleFilter}
            className="mt-4 w-full px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-200"
          >
            Filtrer
          </button>
        </div>
      </div>
      <div className="bg-gray-100 shadow-md rounded-lg p-4 w-full">
        <div className="overflow-x-auto">
          <table className="border-collapse table-auto w-full md:max-w-full md:w-full whitespace-no-wrap bg-white table-striped">
            <thead className="text-sm bg-slate-50">
              <tr className="text-left">
                <th className="sticky top-0 px-6 py-4 border-b border-gray-200 text-gray-400 font-bold tracking-wider">
                  #
                </th>
                {headings.map((heading) => (
  <th
    key={heading.key}
    className={`sticky top-0 px-6 py-4 border-b border-gray-200 text-gray-400 font-bold tracking-wider ${
      heading.key === 'status' ? ' px-14' : ''
    }`}
  >
    {heading.value}
  </th>
))}

                <th className="py-4 px-3 sticky top-0 text-gray-400 border-b border-gray-200 ">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {users.map((user, index) => (
                <tr key={user._id}>
                  <td className="border-dashed border-t font-medium border-gray-200 px-6 py-3 text-gray-600">
                    {users.length - index}
                  </td>
                  {headings.map((heading) => (
                    <td
                      key={`${user._id}-${heading.key}`}
                      className="border-dashed border-t font-medium border-gray-200 px-6 py-3 text-gray-600"
                    >
                      {heading.key === "dateNaissance" ? (
                        calculateAge(user[heading.key])
                      ) : heading.key === "status" ? (
                        <span
                          className={`inline-block text-center mx-1 py-2 w-[100%] rounded-xl text-sm font-semibold ${getStatusColorClass(
                            user[heading.key]
                          )}`}
                        >
                          {getStatusFrenchName(user[heading.key])}
                        </span>
                      ) : heading.key === "createdAt" ? (
                        <span>
                          {new Date(user[heading.key]).toLocaleDateString(
                            "fr-FR"
                          )}
                        </span>
                      ) : (
                        user[heading.key]
                      )}
                    </td>
                  ))}
                  <td
                    className="py-3 px-1 flex whitespace-nowrap items-center"
                    style={{ borderBottom: "1px dotted #ccc" }}
                  >
                    <button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleMenuClick(event, user._id)}
                      className="p-2 font-normal text-md rounded-full text-gray-500 bg-gray-100 hover:text-gray-600"
                    >
                      <MoreVertIcon />
                    </button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl && selectedUserId === user._id)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={(event) => {
                          getRowDetail(event, user._id);
                          handleMenuClose();
                        }}
                      >
                        Voir
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          handleDelete(event, user._id);
                          handleMenuClose();
                        }}
                      >
                        Supprimer
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const getStatusColorClass = (status) => {
  switch (status) {
    case "pending":
      return "bg-orange-500 text-lg  text-white";
    case "rejected":
      return "bg-red-500 text-white text-lg ";
    case "approved":
      return "bg-green-500 text-white text-lg ";
    default:
      return "bg-orange-500 text-white text-lg ";
  }
};

const statusFrenchNames = {
  pending: "En cours",
  rejected: "Rejetée",
  approved: "Approuvée",
};

const getStatusFrenchName = (key) => {
  return statusFrenchNames[key] || "Inconnu";
};

export default Dashboard;
