import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaSignOutAlt, FaChartBar, FaCalendarAlt } from "react-icons/fa";
import { MdOutlineWorkOutline } from "react-icons/md";
import { BsPeople } from "react-icons/bs";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  // Define a function to determine if a link is active
  const isActive = (link) => {
    return link === activeLink;
  };

  return (
    <div className="h-screen w-60 bg-gray-50 text-gray-600 flex flex-col fixed">
      <div className="flex items-center justify-around p-4">
        <div className="flex items-center mt-6">
          <img src="/logo.png" alt="Logo" className="h-40 w-40" />
         </div>
      </div>

      <ul className="space-y-2 p-4 flex-grow">
        <li
          className={`flex items-center space-x-2 p-3 rounded ${
            isActive("/statistics") ? "bg-[#FAB615]" : ""
          }`}
        >
          <FaChartBar className=" font-bold" />
          <Link to="/statistics" className="text-md ">
            Statistiques
          </Link>
        </li>
        <li
          className={`flex items-center space-x-2 p-3 rounded ${
            isActive("/dashboard") ? "bg-[#FAB615] " : ""
          }`}
        >
          <MdOutlineWorkOutline className="font-bold" />
          <Link to="/dashboard" className="text-md text-gray-800">
            Recrutements
          </Link>
        </li>

        <li
          className={`flex items-center space-x-2 p-3 rounded ${
            isActive("/availability") ? "bg-[#FAB615]" : ""
          }`}
        >
          <FaCalendarAlt className=" font-bold" />
          <Link to="/availability" className="text-md ">
            Disponibilité
          </Link>
        </li>
        <li
          className={`flex items-center space-x-2 p-3 rounded ${
            isActive("/agent") ? "bg-[#FAB615]" : ""
          }`}
        >
          <BsPeople className=" font-bold" />
          <Link to="/agent" className="text-md ">
            Agent
          </Link>
        </li>
      </ul>
      <div className="p-4">
        <button
          onClick={handleLogout}
          className="flex items-center text-red-600 space-x-2 p-2 hover:bg-indigo-100 rounded"
        >
          <FaSignOutAlt className="inline-block mr-1" />
          <span>Déconnexion</span>
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
