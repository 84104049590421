import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../utils/config";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    width: "90%",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    border: "1px solid #ccc",
    borderRadius: "8px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const Agent = () => {
  const [agents, setAgents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const postes = [
    "ADS",
    "SSIAP 1",
    "SSIAP 2",
    "Maitre Chien",
    "Opérateurs Rayons X",
  ];

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(`${Config.baseURL}/agent`);
        console.log(response.data);
        setAgents(response.data);
      } catch (error) {
        console.error("Error fetching agents data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedAgent(null);
    reset({
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      poste: "",
      matricules: "",
    });
  };

  const handleAddAgent = async (formData) => {
    if (!formData.matricules) {
      formData.matricules = "N/A";
    }
    try {
      setLoading(true);
      const response = await axios.post(`${Config.baseURL}/agent`, formData);
      setAgents([...agents, response.data]);
      handleCloseModal();
      toast.success("Nouvel agent ajouté avec succès");
    } catch (error) {
      console.error("Error adding new agent:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAgent = async (formData) => {
    try {
      setLoading(true);
      await axios.put(`${Config.baseURL}/agent/${selectedAgent._id}`, formData);
      const updatedAgents = agents.map((agent) =>
        agent._id === selectedAgent._id ? { ...agent, ...formData } : agent
      );
      setAgents(updatedAgents);
      handleCloseModal();
      toast.success("Agent mis à jour avec succès");
    } catch (error) {
      console.error("Error updating agent:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = agents.filter(
    (item) =>
      item.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.telephone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.poste.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDelete = async (id) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer cet agent ?`)) {
      try {
        await axios.delete(`${Config.baseURL}/agent/${id}`);
        const updatedAgents = agents.filter((agent) => agent._id !== id);
        setAgents(updatedAgents);
        toast.success("Agent supprimé avec succès");
      } catch (error) {
        console.error("Error deleting agent:", error);
        toast.error("Erreur lors de la suppression de l'agent");
      }
    }
  };

  const handleMenuClick = (event, agentId) => {
    setAnchorEl(event.currentTarget);
    setSelectedAgentId(agentId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAgentId(null);
  };

  const handleEdit = (agent) => {
    setSelectedAgent(agent);
    reset(agent);
    handleOpenModal();
    handleMenuClose();
  };

  const handleDeleteFromMenu = (id) => {
    handleDelete(id);
    handleMenuClose();
  };

  const getFrenchMonth = (date) => {
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    return months[date.getMonth()];
  };

  const today = new Date();
  const dayOfMonth = today.getDate();

  let displayDate;
  if (dayOfMonth <= 15) {
    displayDate = today;
  } else {
    displayDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  }

  const monthName = getFrenchMonth(displayDate);
  const year = displayDate.getFullYear();

  if (loading) {
    return <Spinner />;
  }

  return (
    <main>
      <header className="px-10 mt-20">
        <h1 className="text-2xl font-bold mb-4">Liste des agents</h1>
        <div className="flex justify-center mb-6">
          <div className="inline-flex items-center px-4 py-2 text-lg font-medium text-white bg-blue-500 rounded-full shadow-lg">
            <span>
              Affichage pour le mois de {monthName} {year}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center flex-wrap">
          <div className="relative flex-1 min-w-0">
            <input
              type="text"
              placeholder="Rechercher..."
              value={searchQuery}
              onChange={handleSearchInputChange}
              className=" pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 "
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </div>
          </div>
          <button
            className="ml-4 mt-4 sm:mt-0 bg-blue-500 text-white px-4 py-2 rounded-md flex items-center"
            onClick={handleOpenModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.75v14.5m7.25-7.25H4.75"
              />
            </svg>
            Ajouter Agent
          </button>
        </div>
      </header>

      <div className="flex flex-col mt-10 mx-5">
        <div className="-mx-4 -my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-100 dark:border-gray-200">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-100 dark:divide-gray-200">
                  <thead className="bg-gray-50 dark:bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-8 py-3.5 text-md text-left font-semibold rtl:text-right text-gray-500"
                      >
                        Prénom
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-md text-left font-semibold rtl:text-right text-gray-500"
                      >
                        Nom
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-md text-left font-semibold rtl:text-right text-gray-500"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-md text-left font-semibold rtl:text-right text-gray-500"
                      >
                        Téléphone
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-md text-left font-semibold rtl:text-right text-gray-500"
                      >
                        Poste
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-md text-left font-semibold rtl:text-right text-gray-500"
                      >
                        Disponibilité
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-md text-left font-semibold rtl:text-right text-gray-500"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-200 bg-white dark:bg-white">
                    {filteredData.map((agent) => (
                      <tr key={agent._id}>
                        <td className="whitespace-nowrap px-8 py-4 text-sm font-medium text-gray-700">
                          {agent.firstName}
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
                          {agent.lastName}
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
                          {agent.email}
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
                          {agent.telephone}
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
                          {agent.poste}
                        </td>
                        <td className="px-4 py-4 text-md text-gray-500 whitespace-nowrap">
                          {agent.isFilledThisMonth ? (
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                              Remplie
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                              En Attente
                            </span>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
                          <IconButton
                            onClick={(event) =>
                              handleMenuClick(event, agent._id)
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={
                              Boolean(anchorEl) && selectedAgentId === agent._id
                            }
                            onClose={handleMenuClose}
                          >
                            <MenuItem onClick={() => handleEdit(agent)}>
                              Modifier
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleDeleteFromMenu(agent._id)}
                            >
                              Supprimer
                            </MenuItem>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Formulaire Agent"
        style={customStyles}
        ariaHideApp={false}
      >
        <h2 className="text-2xl font-bold mb-4">
          {selectedAgent ? "Modifier Agent" : "Ajouter Agent"}
        </h2>
        <form
          onSubmit={handleSubmit(
            selectedAgent ? handleUpdateAgent : handleAddAgent
          )}
        >
          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-md font-medium text-gray-700"
            >
              Prénom <span className="text-red-500">*</span>
            </label>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{ required: "Prénom est requis" }}
              render={({ field }) => (
                <input
                  type="text"
                  id="firstName"
                  {...field}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />
            {errors.firstName && (
              <p className="text-red-500 text-sm mt-1">
                {errors.firstName.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-md font-medium text-gray-700"
            >
              Nom <span className="text-red-500">*</span>
            </label>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{ required: "Nom est requis" }}
              render={({ field }) => (
                <input
                  type="text"
                  id="lastName"
                  {...field}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />
            {errors.lastName && (
              <p className="text-red-500 text-sm mt-1">
                {errors.lastName.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-md font-medium text-gray-700"
            >
              Email <span className="text-red-500">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email est requis",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Adresse email invalide",
                },
              }}
              render={({ field }) => (
                <input
                  type="email"
                  id="email"
                  {...field}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="telephone"
              className="block text-md font-medium text-gray-700"
            >
              Téléphone <span className="text-red-500">*</span>
            </label>
            <Controller
              name="telephone"
              control={control}
              defaultValue=""
              rules={{ required: "Téléphone est requis" }}
              render={({ field }) => (
                <input
                  type="text"
                  id="telephone"
                  {...field}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />
            {errors.telephone && (
              <p className="text-red-500 text-sm mt-1">
                {errors.telephone.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="poste"
              className="block text-md font-medium text-gray-700"
            >
              Poste <span className="text-red-500">*</span>
            </label>
            <Controller
              name="poste"
              control={control}
              defaultValue="ADS"
              render={({ field }) => (
                <select
                  id="poste"
                  {...field}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  {postes.map((poste) => (
                    <option key={poste} value={poste}>
                      {poste}
                    </option>
                  ))}
                </select>
              )}
            />
            {errors.poste && (
              <p className="text-red-500 text-sm mt-1">
                {errors.poste.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="matricules"
              className="block text-md font-medium text-gray-700"
            >
              Matricules
            </label>
            <Controller
              name="matricules"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  type="text"
                  id="matricules"
                  {...field}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mr-2"
              onClick={handleCloseModal}
            >
              Annuler
            </button>
            <button
              type="submit"
              className={`bg-blue-500 text-white px-4 py-2 rounded-md ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSubmitting}
            >
              {selectedAgent ? "Mettre à jour" : "Ajouter"}
            </button>
          </div>
        </form>
      </Modal>
      <ToastContainer />
    </main>
  );
};

export default Agent;
